<!--环节设置-->
<template>
    <div class="segment-setting">
        <div class="setting-content" v-loading="loading">
            <div class="segment-flow">
                <div class="defalut-name">
                    默认审批人
                </div>
                <ul class="flow-list">
                    <li
                        v-for="(list,index) in segmentList"
                        :class="['flow-items',list.length>1?'border':'']"
                        :key="'segment'+index"
                    >
                        <div
                            v-for="item in list"
                            :class="['flow-item',getFlowItemClass(item),segmentActive===item?'active':'']"
                            @click="selectSegment(item)"
                            :key="item.PhaseId"
                        >
                            <div class="flow-peo">
                                <span class="icon"><i class="iconfont iconyonghu"></i></span>
                                <span class="peo-name">{{ item.ApproverRoleName }}</span>
                            </div>
                            <div class="title">
                                {{ item.PhaseName }}
                            </div>
                        </div>
                        <i class="iconfont iconarrow2_right" v-if="index!=segmentList.length-1"></i>
                    </li>
                </ul>
            </div>
            <div class="set-info">
                <div class="btn-set">
                    <h2>按钮设置</h2>
                    <h3>选择按钮</h3>
                    <ul class="btn-list">
                        <li
                            :class="['btn-item',findIndexCheckBtn(item)!==-1?'active':'']"
                            v-for="item in btnAllList"
                            :key="item.ActionId"
                            @click="selectBtn(item)"
                        >
                            <i class="iconfont iconqueding"></i>
                            {{ item.ActionName }}
                        </li>
                    </ul>
                    <h3>修改按钮名称</h3>
                    <div class="btn-edit-table">
                        <table>
                            <tr>
                                <th>按钮名称</th>
                                <td v-for="(item, index) in segmentBtns" :key="`btns_${index}`">
                                    {{ btnAllMapping[item.ActionId].ActionName }}
                                </td>
                            </tr>
                            <tr>
                                <th>自定义按钮名称</th>
                                <td v-for="item in segmentBtns" :key="'custom'+item.ActionId">
                                    <el-input v-model="item.CustomName" placeholder="请输入"></el-input>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="file-set">
                    <h2>附件设置</h2>
                    <div class="flie-set-list">
                        <el-checkbox v-model="filesGroup.IsShowFileGroup" label="是否显示附件页签"></el-checkbox>
                        <el-checkbox v-model="filesGroup.IsRead" label="是否只读"></el-checkbox>
                        <el-checkbox v-model="filesGroup.IsModifyOwner" label="是否只可修改本人上传文件"></el-checkbox>
                        <el-checkbox v-model="filesGroup.IsAllowUpload" label="是否允许上传文件"></el-checkbox>
                        <el-checkbox v-model="filesGroup.IsAllowCreateFolder" label="是否允许创建文件夹"></el-checkbox>
                        <el-checkbox v-model="filesGroup.IsAllowDeleteFolder" label="是否允许删除文件夹"></el-checkbox>
                        <el-checkbox v-model="filesGroup.IsAllowDeleteFile" label="是否允许删除文件"></el-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-footer">
            <div class="btn save-btn" @click="submitSetting">
                保存
            </div>
            <div class="btn close-btn" @click="handleCancel">
                取消
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'segment-setting',
    data() {
        return {
            loading: false,
            phaseid: '', // 当前选中环节id
            segmentActive: 0, // 当前选中得环节索引

            segmentList: [[]], // 环节信息列表
            btnAllList: [], // 按钮列表
            btnAllMapping: {},
            segmentBtns: [], // 按钮列表 选中得
            segmentBtnsChecked: [],

            // 附件配置列表
            filesGroup: {
                PhaseFileGroupId: '',
                PhaseId: '',
                IsShowFileGroup: false,
                IsRead: false,
                IsModifyOwner: false,
                IsAllowUpload: false,
                IsAllowCreateFolder: false,
                IsAllowDeleteFolder: false,
                IsAllowDeleteFile: false,
            },


        };
    },
    props: {
        businessid: String, // 流程id
    },
    created() {
        this.initPage();
    },
    methods: {

        initPage() {
            this.getSegmentInfo();
            this.getBtnList();
        },
        // 选中环节
        selectSegment(item) {
            this.segmentActive = item;
            this.getSegmentBtnList();
            this.getfileInfo();
        },
        // 选中/取消按钮
        selectBtn(item) {
            const index = this.findIndexCheckBtn(item);
            if (index === -1) {
                this.segmentBtns.push({
                    Id: '',
                    PhaseId: this.segmentActive.PhaseId,
                    ActionId: item.ActionId,
                    CustomName: '',
                    ExecuteCondition: '',
                    DisplayCondition: '',
                });
            } else {
                this.segmentBtns.splice(index, 1);
            }
        },
        // 更新修改按钮名称列表
        findIndexCheckBtn(item) {
            let index = -1;
            for (const i in this.segmentBtns) {
                if (this.segmentBtns[i].ActionId === item.ActionId) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        // 获取流程所有环节信息
        getSegmentInfo() {
            this.loading = true;
            this.$axios.get('/interfaceApi/basicplatform/simpleflowphase/config/getphaselist?businessid=' + this.businessid)
                .then(data => {
                    this.segmentList = this.formatSegment(data);
                    // 默认选中第一个
                    this.selectSegment(this.segmentList[0][0]);
                }).finally(() => {
                    this.loading = false;
                });
        },
        // 查找环节会签组
        formatSegment(list) {
            const result = [];
            const tempMap = {}; // 记录组得位置
            list.forEach(item => {
                if (item.PhaseType === 3 ) {
                    const index = tempMap[item.PhaseGroupId] || -1;
                    if (result[index]) {
                        result[index].push(item);
                    } else {
                        tempMap[item.PhaseGroupId] = result.length;
                        result.push([item]);
                    }
                } else {
                    result.push([item]);
                }
            });
            return result;
        },
        // 获取环节附件配置
        getfileInfo() {
            this.$axios.get('/interfaceApi/basicplatform/simpleflowphase/config/getphasefilegroup?phaseid=' + this.segmentActive.PhaseId,)
                .then(data => {
                    this.filesGroup = data;
                });
        },
        // 获取按钮列表
        getBtnList() {
            this.$axios.get('/interfaceApi/basicplatform/wfconfigaction/config/getactionlist?businessid=' + this.businessid)
                .then(data => {
                    this.btnAllList = data || [];
                    this.btnAllList.forEach(item => {
                        this.btnAllMapping[item.ActionId] = item;
                    });
                });
        },
        // 获取选中环节按钮配置
        getSegmentBtnList() {
            this.$axios.get('/interfaceApi/basicplatform/simpleflowphase/config/getactionlist?phaseid=' + this.segmentActive.PhaseId,)
                .then(data => {
                    this.segmentBtns = data || [];
                });
        },
        // 保存设置
        async submitSetting() {
            try {
                const res1 = await this.saveSegmentBtnSetting();
                const res2 = await this.saveFileGroup();
                if (res1 && res2) {
                    this.$message.success('保存成功');
                }
            } catch (e) {
                if (e.ErrorCode) {
                    this.$message(e.ErrorCode.Message);
                }
            }

        },
        // 保存环节按钮信息
        async saveSegmentBtnSetting() {
            const url = `/interfaceApi/basicplatform/simpleflowphase/config/savephaseactions/${this.segmentActive.PhaseId}`;
            return this.$axios.post(url, this.segmentBtns);
        },
        // 保存附件配置
        async saveFileGroup() {
            const url = '/interfaceApi/basicplatform/simpleflowphase/config/savephasefilegroup';

            return this.$axios.post(url, this.filesGroup);
        },
        handleCancel() {
            this.selectSegment(this.segmentActive);
        },
        // 获取样式
        getFlowItemClass(item) {
            if (item.PhaseType === 1) {
                return '';
            }
            if ([2, 3, 5].includes(item.PhaseType)) {
                return 'item2';
            }
            if (item.PhaseType === 5) {
                return 'item3';
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.segment-setting
    width 100%;
    height calc(100% - 0.6rem);
    background: #fafafb
    overflow hidden
    position relative
    .setting-content
        width 100%;
        height calc(100% - 0.7rem);
        overflow-y auto
        .segment-flow
            padding 0.3rem 0.6rem;
            background #fff;
            display flex
            .defalut-name
                width 1rem;
                padding-top 0.3rem;
                font-size 0.14rem;
                color #5C6A76;
            .flow-list
                display flex
                flex-wrap wrap
                flex 1
                .flow-items
                    position relative;
                    padding 0.1rem
                    margin 0 0.1rem;
                    &.border
                        border 1px solid #D7D7D7;
                    .iconarrow2_right
                        position absolute
                        right: -0.17rem;
                        top: 0.3rem;
                        font-size 16px;
                        color #D7D7D7
                    .flow-item
                        width 1.7rem;
                        height 0.8rem;
                        padding 10px;
                        position relative;
                        box-sizing border-box
                        &.active
                            box-shadow: 0rem 0.02rem 0.06rem 0rem rgba(0, 0, 0, 0.3);
                            border: 0.02rem solid #0091FF;
                        .flow-peo
                            display flex
                            align-items center
                            width: 1.5rem;
                            height: 0.4rem;
                            background: #E2FCDC;
                            border-radius: 2px;
                            box-sizing border-box;
                            padding 0.08rem 0.1rem;
                            font-size 0.14rem;
                            color #2F2E2E;
                            cursor: pointer;
                            .peo-name
                                width 1rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            .icon
                                display flex
                                justify-content center
                                align-items center
                                width: 0.24rem;
                                height: 0.24rem;
                                background: #44D76E;
                                border-radius: 50%;
                                margin-right 0.1rem;
                                .iconyonghu
                                    font-size 0.14rem;
                                    color #fff;
                        &.item2 .flow-peo
                            background #DCF5FC
                            .icon
                                background #17A8FD
                        &.item3 .flow-peo
                            background #FFF1F0
                            .icon
                                background #FF595B
                        .title
                            font-size 0.14rem;
                            color #DC8D1D;
                            margin-top 0.03rem;
                            padding-left 0.05rem;
        .set-info
            border-top 1px solid #D7D7D7;
            padding 0.3rem 0.4rem;
            h2
                font-size 0.2rem;
                color #333333
            h3
                margin-top 0.1rem;
                font-size 0.16rem;
                color #333333
            .btn-set
                .btn-list
                    display flex
                    flex-wrap wrap
                    .btn-item
                        display flex
                        justify-content center
                        align-items center
                        margin 0.1rem 0.2rem 0.1rem 0;
                        width: 1rem;
                        height: 0.4rem;
                        background: #F1F1F1;
                        border-radius: 0.02rem;
                        border: 1px solid #D7D7D7;
                        font-size 0.16rem;
                        color #666666
                        overflow hidden;
                        text-overflow ellipsis
                        white-space nowrap
                        cursor: pointer;
                        .iconfont
                            display none;
                            font-size 0.14rem;
                            color #fff;
                        &.active
                            border: 1px solid #1890FF;
                            color #1890FF
                            background #fff;
                            position relative
                            .iconfont
                                display inline-block
                                position absolute
                                top 0.02rem;
                                left 0.02rem;
                            &:before
                                display flex
                                width 0.6rem;
                                height 0.6rem;
                                content ''
                                background #1890FF
                                position absolute
                                left -36px;
                                top -36px
                                transform rotate(-45deg);
                .btn-edit-table
                     width 100%;
                     overflow-x auto;
                     table
                         margin-top 0.1rem;
                         table-layout fixed
                         border-collapse:collapse;
                         border:none;
                         white-space nowrap
                         th,td
                             border 1px solid #D7D7D7
                             font-size 0.16rem;
                         th
                             padding 0.14rem 0.19rem
                             color #022782;
                             background #EDF0F5
                         td
                             width 0.9rem;
                             color #333333;
                             text-align center;
                             >>> .el-input__inner
                                text-align center
                                border 0;
            .file-set
                margin-top 0.4rem;
                >>> .el-checkbox
                    margin-top 0.2rem
                    margin-right 0.4rem;
    .dialog-footer
        padding 0.2rem 0 0;
        display flex
        justify-content center
        position absolute
        bottom: 0.25rem;
        left: 0;
        width: 100%;
        .btn
            margin 0 0.3rem;
            width: 1.3rem;
            height: 0.36rem;
            line-height 0.36rem;
            text-align center
            border-radius: 0.01rem;
            cursor pointer
        .save-btn
            background: #1577D2;
            color #fff;
        .close-btn
            border: 0.01rem solid #1577D2;
            color #1577D2

</style>
